import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import partExampleImg from '../assets/images/140.png';

const PartsTableStyles = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;

  .tableRow {
    display: table-row;
    border-bottom: 1px dashed var(--black);
    table-layout: fixed;
    > div {
      display: table-cell;
      padding: 10px 20px;
      text-align: left;
      vertical-align: middle;
      :first-child {
        width: 120px;
        padding-left: 0;
        text-align: left;
        figure,
        p {
          display: inline-block;
        }
      }
      :last-child {
        width: 140px;
        padding-right: 0;
      }
    }
    &.tableHead {
      border-bottom-style: solid;
      border-width: 3px;
      > div {
        padding-top: 0;
      }
    }
    h5 {
      font-size: 16px;
      line-height: 20px;
      color: var(--black);
      font-weight: 500;
    }
    p {
      font-family: 'RobotoSlab';
      font-size: 16px;
      line-height: 24px;
      color: #001926;
      span {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
      }
    }
    :last-child {
      border-bottom: 0;
      > div {
        padding-bottom: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .tableRow {
      > div {
        padding: 10px 5px;
        :first-child {
          width: 60px;
        }
        :last-child {
          width: 55px;
          h5,
          p {
            text-align: right;
          }
        }
      }
      h5 {
        font-size: 12px;
        line-height: 14px;
      }
      p span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const CustomCheckboxStyles = styled.div`
  position: relative;

  .customCheckbox {
    display: none;
  }

  label {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    background-color: var(--grey);
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease transform, 0.2s ease background-color,
      0.2s ease box-shadow;
    overflow: hidden;
    z-index: 1;
    border: 2px solid #444;
  }

  label:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background-color: #fff;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: 0.2s ease width, 0.2s ease height;
    border: 1px solid #444;
  }

  label:active {
    transform: translateY(-50%) scale(0.9);
  }

  .tick_mark {
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin-left: 9px;
    transform: rotateZ(-40deg);
  }

  .tick_mark:before,
  .tick_mark:after {
    content: '';
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
    opacity: 0;
    transition: 0.2s ease transform, 0.2s ease opacity;
  }

  .tick_mark:before {
    left: 0;
    bottom: 0;
    width: 6px;
    height: 16px;
    transform: translateY(-68px);
  }

  .tick_mark:after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    transform: translateX(78px);
  }

  .customCheckbox:checked + label {
    background-color: #07d410;
  }

  .customCheckbox:checked + label:before {
    width: 0;
    height: 0;
    border: 0;
  }

  .customCheckbox:checked + label .tick_mark:before,
  .customCheckbox:checked + label .tick_mark:after {
    transform: translate(0);
    opacity: 1;
  }
`;

export default function CarLoadingDetailsPage({ data, pageContext }) {
  const details = data.stove;
  const breadcrumbs = [
    {
      title: 'Car loading',
      url: '/car-loading',
    },
    {
      title: pageContext.familyName,
      url: `/car-loading/${pageContext.familyUrl}`,
    },
  ];

  const parts = [];
  details.parts.map((item) =>
    parts.push({
      quantity: item.quantity,
      comment: item.comment,
      article_number: item.building_parts.article_number,
      name: item.building_parts.name,
      warehouse_position: item.building_parts.warehouse_position,
      image:
        item.building_parts.image != null
          ? item.building_parts.image.asset.fluid.src
          : partExampleImg,
    })
  );

  console.info(parts);

  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <PartsTableStyles>
        <div className="tableRow tableHead">
          <div>
            <h5>image</h5>
          </div>
          <div>
            <h5>name</h5>
          </div>
          <div>
            <h5>position</h5>
          </div>
          <div>
            <h5>quantity</h5>
          </div>
          <div />
        </div>
        {parts.map((item, index) => (
          <div key={index} className="tableRow">
            <div>
              <figure>
                <img src={item.image} alt={item.name} />
              </figure>
            </div>
            <div>
              <p>
                <span>{item.name}</span>
                <br />
                {item.article_number}
              </p>
            </div>
            <div>
              <p>
                <span>{item.warehouse_position}</span>
              </p>
            </div>
            <div>
              <p>
                <span>{item.quantity}</span>
              </p>
            </div>
            <CustomCheckboxStyles>
              <input type="checkbox" id={index} className="customCheckbox" />
              <label htmlFor={index}>
                <div className="tick_mark" />
              </label>
            </CustomCheckboxStyles>
          </div>
        ))}
      </PartsTableStyles>
    </Layout>
  );
}

export const query = graphql`
  query CarQuery($url: String) {
    stove: sanityStove(url: { current: { eq: $url } }) {
      id
      family {
        name
        url {
          current
        }
        id
      }
      parts {
        building_parts {
          ... on SanityPart {
            id
            name
            article_number
            warehouse_position
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        quantity
        comment
      }
    }
  }
`;
